<template>
  <PageLayout>
    <MDBLoading v-model="loadingSpinner" class="analytics-loader" loadingText="" icon="spinner-grow" />
    <h1 class="text-center">Update User Account</h1>
    <MDBCard id="UserInformation" class="mb-2" tag="form">
      <MDBCardHeader class="p-4">
        <h4 class="mb-0">User Information</h4>
      </MDBCardHeader>
      <MDBCardBody class="d-flex flex-column" style="gap: 15px">
        <div>
          Email:
          <MDBInput type="email" placeholder="Email" v-model="usersStore.users.email" />
        </div>
        <div>
          Partner Percentage:
          <MDBInput type="number" placeholder="Partner Percentage" v-model="usersStore.users.partnerPercentage" />
        </div>
        <!-- toggle -->
        <div class="mt-1">
          <MDBSwitch label="Is Distributor" v-model="usersStore.users.isDistributor" />
        </div>
        <!-- google sheet -->
        <div v-if="usersStore.users.isDistributor" class="gap-4">
          Google Sheet Info:
          <MDBInput type="text" placeholder="Google Sheet Link" v-model="usersStore.users.googleSheetLink"
            class="mt-3" />
          <MDBInput type="text" placeholder="Google Sheet's SheetName" v-model="usersStore.users.sheetName"
            class="mt-3" />
        </div>
        <!-- asset label -->
        <div class="mt-2">
          <MDBBtn type="button" color="secondary" aria-controls="assetLabelModal" @click="assetLabelModal = true"
            size="sm">
            Select Asset Labels
          </MDBBtn>
          <MDBTable hover responsive class="mt-3">
            <tr>
              <th v-for="(item, i) in tableHeaders" :key="i" scope="col">
                {{ item.header }}
              </th>
            </tr>
            <tbody v-if="usersStore.assetLabels.length < 1">
              <tr class="text-center align-middle">
                <td colspan="2" class="d-flex align-items-center ">
                  <h4>No Selected Asset Label</h4>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="usersStore.assetLabels.length > 0">
              <tr v-for="(item, i) in usersStore.assetLabels" :key="i">
                <td class="align-middle">{{ item.name }}</td>
                <td class="d-flex justify-content-end text-right">
                  <MDBBtn color="danger" floating @click.stop.prevent="clickDelete(item)" size="sm">
                    <span class="far fa-trash-alt" />
                  </MDBBtn>
                </td>
              </tr>
            </tbody>
          </MDBTable>
        </div>
      </MDBCardBody>
      <MDBCardFooter class="py-3 d-flex justify-content-end">
        <MDBBtn type="submit" @click="submit" color="primary" :disabled="isUpdateBtnDisabled" size="sm">
          Update User
        </MDBBtn>
      </MDBCardFooter>
    </MDBCard>
    <!-- modal -->
    <MDBModal id="assetLabelModal" tabindex="-1" labelledby="assetLabelModalLabel" v-model="assetLabelModal" scrollable>
      <MDBModalHeader class="px-4">
        <MDBModalTitle id="assetLabelModalLabel"> Asset Labels </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>
        <div>
          <MDBInput class="mb-4" v-model="dataTableSearch" />
          <MDBDatatable :dataset="usersStore.assetLabelDataTable" selectable multi :search="dataTableSearch"
            @selected-rows="handleSelectedRow($event)" hover />
        </div>
      </MDBModalBody>
      <MDBModalFooter class="gap-2 py-3">
        <MDBBtn size="sm" @click="assetLabelModal = false"> Close </MDBBtn>
        <MDBBtn size="sm" color="primary" @click="assetLabelModal = false">
          Select Asset Labels
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  </PageLayout>
</template>

<script setup>
import {
  MDBBtn,
  MDBLoading,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalTitle,
  MDBModalFooter,
  MDBDatatable,
  MDBInput,
  MDBSwitch,
  MDBTable,
} from "mdb-vue-ui-kit";
import LayoutNew from "./LayoutNew.vue";
import VVVLayout from "@/components/VVV/VVVLayout.vue";
import { onMounted, ref, watch, computed } from "vue";
import axios from "axios";
import { useUsersStore } from "@/store/usersStore";
import { useRoute, useRouter } from "vue-router";
import { useTitle } from "@vueuse/core";
import { isVVV } from "@/helpers/siteIdentifier";

useTitle(`User Settings | ${isVVV() ? 'Very Viral Visuals' : 'Creator Shields'}`);

let PageLayout = VVVLayout;
if (isVVV()) {
  PageLayout = VVVLayout;
} else {
  PageLayout = LayoutNew;
}

const usersStore = useUsersStore();
const route = useRoute();
const router = useRouter();
const userId = route.params.id;
const assetLabelModal = ref(false);

const tableLoader = ref(false);
const dataTableSearch = ref("");

onMounted(async () => {
  tableLoader.value = true;
  await axios
    .get("api/assetLabels/Dropdown")
    .then(function (data) {
      usersStore.assetLabelDataTable.rows = data.data;
      usersStore.getUsers({ UserId: userId });
      usersStore.getAssetLabels({ UserId: userId });
      tableLoader.value = false;
    })
    .catch(function (error) {
      toastObject.value.state = true;
      toastObject.value.message = "An error occurred during request" + error;
      toastObject.value.color = "danger";
      toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
    });
});

const handleSelectedRow = (selectedModalArray) => {
  const newArray = [
    ...new Set([...usersStore.assetLabels, ...selectedModalArray]),
  ];
  usersStore.assetLabels = newArray;
};

const clickDelete = (item) => {
  const newArray = usersStore.assetLabels.filter(
    (element) => element.id != item.id
  );
  usersStore.assetLabels = newArray;
};

const toastObject = ref({
  state: false,
  message: "",
  color: "",
  icon: "",
  title: "",
});

let loadingSpinner = ref(false);

const selectedAssetLabels = ref("");
watch([selectedAssetLabels], ([newSelectedAssetLabels]) => {
  usersStore.assetLabels = newSelectedAssetLabels.split(",");
});

const tableHeaders = computed(() => [
  {
    header: "Name",
  },
  {},
]);

const isUpdateBtnDisabled = computed(() => usersStore.users.email == "");

const submit = () => {
  const formData = {
    email: usersStore.users.email,
    userTypeId: getUserTypeId.value,
    googleSheetLink: usersStore.users.isDistributor ? usersStore.users.googleSheetLink : null,
    assignedAssetLabels: usersStore.assetLabels,
    partnerPercentage: usersStore.users.partnerPercentage,
    id: usersStore.users.id,
    sheetName: usersStore.users.isDistributor ? usersStore.users.sheetName : null,
  };

  loadingSpinner.value = true;
  axios
    .put("api/users", formData)
    .then(function (response) {
      if (response.status == 200 || response.status == 201) {
        toastObject.value.title = "Success!";
        toastObject.value.state = true;
        toastObject.value.message = "Successfully submitted!";
        toastObject.value.color = "success";
        toastObject.value.icon = "fas fa-check fa-lg me-2";
        loadingSpinner.value = false;
        router.push({ name: "UserManagement" });
      } else {
        toastObject.value.title = "Error!";
        toastObject.value.state = true;
        toastObject.value.message = "An error occurred during request";
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        loadingSpinner.value = false;
      }
    })
    .catch(function (error) {
      toastObject.value.title = "Error!";
      toastObject.value.state = true;
      toastObject.value.message = "An error occurred during request" + error;
      toastObject.value.color = "danger";
      toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
      loadingSpinner.value = false;
    });
};

const getUserTypeId = computed(() => (usersStore.users.isDistributor ? 3 : 2));
</script>

<style>
:root {
  --white: #ffffff;
  --primary: #fc5818;
  --secondary: #e2e6ef;
  --accent: #02061b;
}
</style>

<style scoped>
.btn-primary {
  background-color: var(--primary);
}

.btn-secondary {
  background-color: var(--accent);
}

.card,
.card-body {
  overflow-y: auto;
}
</style>
